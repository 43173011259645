import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PromanButtonComponent } from '@proman/button/proman-button.component';
import { CropperDialogComponent } from '@proman/cropper/cropper-dialog.component';
import { MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { Fa6Module } from '@proman/fa/fa6.module';
import { FlexModule } from 'ngx-flexible-layout';
import { PipesModule } from '@proman/shared/pipes/pipes.module';

@NgModule({
    imports: [
        CommonModule,
        MatLegacyDialogModule,
        PromanButtonComponent,
        Fa6Module,
        FlexModule,
        PipesModule,
    ],
    declarations: [
        CropperDialogComponent,
    ],
    exports: [CropperDialogComponent]
})
export class CropperModule {

}
