import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Entity } from '@proman/services/entity.service';
import moment from 'moment';
import { FilePreviewService } from '@proman/services/file-preview.service';
import { Employee, EmployeeDocument, PromanFile } from '@proman/interfaces/entity-interfaces';
import { EmployeeDocumentEntityInterface } from '@proman/resources/employee-document';
import { EmployeeEntityInterface } from '@proman/resources/employee';
import { EmployeesService } from '@frontend/employees/services/employees.service';
import { Dialog } from '@frontend/shared/services/dialog.service';
import { CanvasSignDialogComponent } from '../../shared-dialogs/components/canvas-sign-dialog.component';
import { getScreenWidth } from '@proman/utils';
import { ImagePathService } from '@proman/services/image-path.service';
import { Store } from '@ngrx/store';
import { getCurrUser } from '@proman/store/curr-user';
import { filter } from '@proman/rxjs-common';

@Component({
    selector: 'pm-employee-documents-read',
    template: `
        <mat-accordion>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>{{ 'documents' | translate }}</mat-panel-title>
                </mat-expansion-panel-header>
                <mat-list-item *ngFor="let document of employeeDocuments" fxLayout="row" class="Padding-4">
                    <div fxLayout="row">
                        @if (!document.isRead && isMe) {
                            <pro-checkbox [value]="document.read"
                                          (onChange)="read(document)">
                            </pro-checkbox>
                        }
                        @if (document.signature) {
                            <pro-thumbnail [file]="document.signature"></pro-thumbnail>
                        }

                        <div fxLayout="row" class="EmployeeDocumentRow Clickable"
                             (click)="preview(document)">
                            <fa name="file" class="RightPadding"></fa>
                            <div class="RightPadding">{{ document.name }}
                                @if (document.isRead) {
                                    <div><strong>{{ 'signed' | translate }}: </strong>{{ document.isRead }}</div>
                                }
                            </div>

                        </div>
                        <pro-btn *ngIf="document.isRead && canRemoveSignature" icon="pen-slash"
                                 [tooltip]="'unsign_document' | translate" theme="warn"
                                 (onClick)="removeSignature(document)"></pro-btn>
                    </div>

                </mat-list-item>
            </mat-expansion-panel>
            <br><br><br>
        </mat-accordion>
    `,
    styles: [`
        .EmployeeDocumentRow {
            font-size: 13px;
        }
    `]
})

export class EmployeeDocumentsReadComponent implements OnInit {
    @Input() employee: Employee;
    employeeEntity: EmployeeEntityInterface;
    employeeDocumentEntity: EmployeeDocumentEntityInterface;
    employeeDocuments: EmployeeDocument[];
    @Output() onReadAll: EventEmitter<any> = new EventEmitter<any>();
    isMe: boolean;
    canRemoveSignature: boolean;
    signature: string;

    constructor(
        private Entity: Entity,
        private FilePreview: FilePreviewService,
        private ImagePath: ImagePathService,
        private Dialog: Dialog,
        private Employees: EmployeesService,
        private store: Store<unknown>,
    ) {
        this.employeeDocumentEntity = this.Entity.get('employee_document');
        this.employeeEntity = this.Entity.get('employee');
        this.store.select(getCurrUser).pipe(filter((value) => !!value)).subscribe((value) => {
            value.person.specialisations.forEach((spec) => {
                if (spec.type === 'administrator') this.canRemoveSignature = true;
            })
        })
    }

    ngOnInit() {

        this.isMe = this.Employees.isMe(this.employee);

        this.refresh();
    }

    read = (item: any) => {
        const signRequest = () => {
            this.employeeEntity.documentRead({ employeeId: this.employee.id, employeeDocumentId: item.id, data: this.signature }).then(() => {
              this.signature = null;
              this.refresh();
            });
        };
        if (this.signature) {
            signRequest();
        } else {
            this.Dialog.open(CanvasSignDialogComponent, {}, { width: `${getScreenWidth(80)}px`, disableClose: true })
                .then((res: { position: string; data: string }) => {
                    console.log('Dialog data');
                    console.log(res);
                    this.signature = res.data;
                    this.read(item);
                });
        }
    };

    preview = (item: { link: string, file: PromanFile }) => {
        if (item.file) {
            this.FilePreview.show(item.file);
        } else if (item.link) {
            window.open(item.link, '_blank');
        }
    };

    refresh = () => {
        this.employeeDocumentEntity
            .getByEmployee(this.employee.id)
            .then((response) => {
                if (response) {
                    this.employeeDocuments = response.documents;

                    response.reads.forEach((read) => {

                        this.employeeDocuments.forEach((item) => {

                            if (item.id === read.documentId && !item.signature) {
                                item.isRead = moment(read.date).format('YYYY-MM-DD HH:mm');
                                item.signature = read.signature;
                                item.readId = read.id;
                            }

                        });
                    });

                    if (response.reads.length >= response.documents.length) {
                        this.onReadAll.emit(true);
                    }

                }
            });
    };

    removeSignature = (document: EmployeeDocument) => {
        this.Entity.get('employee_document_read').remove({ id: document.readId }).then(() => {
            document.read = null;
            document.readId = null;
            document.signature = null;
            this.refresh();
        });
    };

}
