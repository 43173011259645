import { Component, Input, OnInit } from '@angular/core';
import { Entity, EntityNameType } from '@proman/services/entity.service';
import { prepareRequest, utcFormat } from '@proman/utils';
import { resourcesConfig } from '@proman/resources';
import { FilterService } from '@proman/services/filter.service';
import moment from 'moment';
import { CurrUser } from '@proman/interfaces/object-interfaces';
import { Store } from '@ngrx/store';
import { getCurrUser } from '@proman/store/curr-user';

@Component({
    selector: 'pm-inline-comments-component',
    template: `
        <div class="List" fxLayout="column">
            <div class="List-header"
                 fxLayout="row"
                 fxLayoutAlign="start center">
                <pro-label>{{ label | translate }}</pro-label>
                <pro-btn
                        *ngIf="!isReadOnly && !isAddCommentMode"
                        (click)="enableAddMode()"
                        icon="plus"
                        theme="accent"></pro-btn>
            </div>
            <div class="InlineForm custom-animation"
                 fxLayout="column"
                 *ngIf="isAddCommentMode">
                <pm-txt [value]="newComment.text"
                        [config]="{ label: 'operations_comment', type: 'textarea' }"
                        (onChange)="set('text', $event)"></pm-txt>
                <pro-select [value]="newComment.type"
                        [config]="{ label: 'type', disableSearch: true }"
                        [options]="types"
                        (onChange)="set('type', $event)"></pro-select>
                <div fxLayout="row"
                     fxLayoutAlign="start center">
                    <div class="Btn-actions"
                         fxLayout="row">
                        <pro-btn
                                (click)="addComment()"
                                pattern="add"></pro-btn>
                        <pro-btn
                                (click)="isAddCommentMode = false"
                                pattern="cancel"></pro-btn>
                    </div>
                    <div fxFlex></div>
                    <pro-checkbox [value]="newComment.isImportant"
                                 [config]="{ label: 'is_important' }"
                                 (onChange)="toggleImportant($event)"></pro-checkbox>
                </div>
            </div>
            <div *ngIf="!comments?.length"
                 fxLayout="column">
                <hr>
                <pro-no-records></pro-no-records>
            </div>
            <div class="List-row"
                 *ngFor="let comment of comments"
                 fxLayout="row" fxFlex>
                <div fxLayout="column" class="Width100">
                    <div class="List-row--extra">
                        {{ comment.author?.name }}
                    </div>
                    <div class="List-row--extra small BottomMargin">
                        {{ comment.date | proDateTime }}
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px" fxFlex *ngIf="!comment.isEdit">
                        <div [innerHTML]="comment.text"></div>
                        <pro-btn *ngIf="comment.author?.id == currUser.person?.id && !isReadOnly"
                                icon="edit"
                                theme="accent"
                                size="1x"
                                (onClick)="editComment(comment)"
                        ></pro-btn>

                    </div>
                    <div fxLayout="row" fxFlex *ngIf="comment.isEdit">
                        <pm-txt [value]="comment.text"
                                [config]="{ label: 'edit', debounce: 5000, autofocus: true, type: 'textarea' }"
                                (onChange)="editCommentValue(comment, $event)"
                                [disabled]="isReadOnly"
                        ></pm-txt>
                    </div>
                </div>
            </div>
        </div>
    `
})

export class InlineCommentsComponent implements OnInit {
    @Input() entityName: EntityNameType;
    @Input() entity: any;
    @Input() label: string;
    @Input() comments: any[];
    @Input() isReadOnly: boolean;
    currUser: CurrUser;

    Entity: any;
    newComment: any = {};
    isAddCommentMode: boolean = false;
    types: any;

    constructor(
        private EntityService: Entity,
        private Filter: FilterService,
        private store: Store,
    ) {
        this.store.select(getCurrUser)
            .subscribe((value) => this.currUser = value);
    }

    ngOnInit() {

        if (this.entityName) {
            this.Entity = this.EntityService.get({ name: this.entityName });

        }

        this.types = resourcesConfig.article_operation_production_comment.params.types
            .map((item: string) => {
                return {
                    id: item,
                    name: this.Filter.translate(item)
                }
            });

        this.newComment.type = this.types[0];

    }

    addComment() {
        let data: any = prepareRequest(this.newComment);

        data.date = utcFormat(moment());
        data.event = this.entity.id;

        this.Entity
            .create(data)
            .then((response: any) => {
                this.Entity.get({
                    id: response,
                    join: ['author']
                }).then((response: any) => {
                    this.isAddCommentMode = false;

                    this.comments.unshift(response);
                });
        });
    };

    set(property: string, value: any) {
        this.newComment[property] = value;
    };

    enableAddMode() {
        this.newComment = { text: '', type: this.types[0] };

        this.isAddCommentMode = true;
    }

    toggleImportant(value: boolean) {
        this.newComment.isImportant = value;
    }

    editComment(comment: any) {
        comment.isEdit = true;
    }

    editCommentValue(comment: any, value: string) {
        comment.text = value;

        this.Entity.update(prepareRequest(comment));

        comment.isEdit = false;
    }

}
