import { Component, Input, OnInit } from '@angular/core';
import { addMoney } from '@proman/utils';
import { Entity } from '@proman/services/entity.service';
import { ActivatedRoute } from '@angular/router';
import { CONFIG } from '@proman/config';
import { InputDialogComponent } from '@frontend/shared/components/input-dialog.component';
import { Dialog } from '@frontend/shared/services/dialog.service';
import { COOKIE_AUTHORIZATION, CookiesService } from '@proman/services/cookies.service';
import { Money } from '@proman/interfaces/common.interface';
import { ArticlesService } from '../services/articles.service';
import { CurrenciesService } from '@proman/services/currencies.service';
import { ACL } from '@proman/services/acl.service';
import {
  ArticleChild,
  ArticleOperation, ArticleProductionParameter,
  EventComment,
  FeatureValue,
  PromanFile
} from '@proman/interfaces/entity-interfaces';
import { FilePreviewService } from '@proman/services/file-preview.service';

@Component({
  selector: 'pm-article-summary',
  template: `
    <div fxLayout="column" class="Padding-16">
      @for (error of item.erros; track $index) {
        <div>
          <div class="Alert">{{ error }}</div>
        </div>
      }

      <div fxLayout="row">
        <pro-btn icon="print" [tooltip]="'print_template' | translate" [tooltipPosition]="'right'"
                 theme="accent" (onClick)="print()"></pro-btn>
        @if (pdfArray?.length > 0) {
          <pro-btn icon="file-pdf" [tooltip]="'download_pdfs' | translate"
                   [tooltipPosition]="'right'"
                   theme="accent" (onClick)="downloadPdfs()"></pro-btn>
        }
      </div>

      <div fxLayout="row" fxFlex fxLayoutAlign="space-between center">
        @if (features.length) {
          <div>
            <pro-label>{{ 'features' | translate }}</pro-label>
            @for (feature of features; track $index) {
              <pro-editable-container [label]="feature.name">
                @for (option of feature.options; track $index) {
                  <span>{{ option.name | translate }}<br></span>
                }
              </pro-editable-container>
            }
          </div>
        }

        <pm-image [image]="item.photo" [options]="{ width: 400, height: 300 }"></pm-image>
      </div>

      @if (ACL.check('article.edit')) {
        <pm-article-summary-materials [article]="item"></pm-article-summary-materials>
      }

      @if (ACL.check('article.edit')) {
        <div fxLayout="row" fxLayoutAlign="end center">
          <div fxFlex="100px">
            <pro-editable-container label="sum">
              <div [innerHTML]="totalQuantityBrutto"></div>
            </pro-editable-container>
          </div>
          <div fxFlex="100px">
            <pro-editable-container label="sum">
              <div [innerHTML]="totalQuantityNetto"></div>
            </pro-editable-container>
          </div>
          <div fxFlex="100px"></div>
          <pro-editable-container label="sum">{{ totalPrice | proPrice:4 }}</pro-editable-container>
          <div fxFlex="60px;"></div>
        </div>
      }

      @if (operations.length && ACL.check('article.edit')) {
        <div>
          <pro-label>{{ 'operations' | translate }}</pro-label>
          @for (ao of operations; track $index) {
            <div>
              <div class="BottomMargin" fxLayout="row">
                @if (ao.files[0]) {
                  <pm-image class="RightPadding" [image]="ao.files[0]"></pm-image>
                }
                <div fxLayout="column" fxFlex>
                  <div fxLayout="row" fxFlex style="justify-content: space-between; margin-right: 60px">
                    <a [routerLink]="'ArticleOperation' | pmSref: [  ao.article.id,  ao.id ]">
                      {{ ao.operation.name }}
                    </a>
                    <div style="float: right">{{
                        {
                          amount: ao.standardCost.toString(),
                          currency: defaultCurrency
                        } | proPrice: 2
                      }}
                    </div>
                  </div>

                  <div [innerHTML]="ao.technicalDescription"></div>
                  @if (ao.articleOperationWorkplaces?.length) {
                    <div class="TopPadding">
                      @for (w of ao.articleOperationWorkplaces; track $index) {
                        <span>{{ w.workplace.name }}, </span><br />
                      }
                    </div>
                  }
                </div>
              </div>
            </div>
          }
          <h4>Total operation cost: {{
              {
                amount: standardOperationsCost.toString(),
                currency: defaultCurrency
              } | proPrice: 2
            }} </h4>
        </div>
      }
      <br />

      @if (item.description) {
        <div class="BottomMargin" fxLayout="row">
          @if (item.photo) {
            <pm-image class="RightPadding" [image]="item.photo"></pm-image>
          }
          <div fxLayout="column">
            <pro-label>{{ 'description' | translate }}</pro-label>
            <div [innerHTML]="item.description"></div>
          </div>
        </div>
      }

      @if (item.technicalDescription) {
        <div class="BottomMargin" fxLayout="row">
          <div fxLayout="column">
            <pro-label>{{ 'technical_description' | translate }}</pro-label>
            <div [innerHTML]="item.technicalDescription"></div>
          </div>
        </div>
      }

      @if ((item.parameters?.length || item.parameterGroups?.length) && ACL.check('article.edit')) {
        <div>
          <pro-label>{{ 'parameters' | translate }}</pro-label>
          @for (articleParameter of item.parameters; track $index) {
            <div>
              <pro-parameter class="parameterColor" [parameter]="articleParameter"
                             [config]="{ isVisibility: true}"
                             [disabled]="true"></pro-parameter>
            </div>
          }
          <table class="Table parameterColor">
            <thead>
            <tr>
              <th>{{ item.parameterGroups[0]?.parameter.name }}</th>
              @for (childParameter of item.parameterGroups[0]?.children; track $index) {
                <th>{{ childParameter.parameter.name }}</th>
              }
            </tr>
            </thead>
            <tbody>
              @for (parameterGroup of item.parameterGroups; track $index) {
                <tr>
                  <td>{{ parameterGroup.name }}</td>
                  @for (childParameter of parameterGroup?.children; track $index) {
                    <td>
                      <pro-parameter [parameter]="childParameter"
                                     [config]="{}"
                                     [disabled]="true"></pro-parameter>
                    </td>
                  }
                </tr>
              }
            </tbody>
          </table>
        </div>
      }
    </div>
  `,
  styles: [`
    h4 {
      margin: 0.5em 0;
    }

    .parameterColor mat-slide-toggle {
      color: #ba68c8;
      opacity: 1;
    }
  `]
})

export class ArticleSummaryComponent implements OnInit {
    @Input('article') item: any;
    materialCategories: any = [];
    features: any = [];
    operations: any[] = [];
    subproducts: ArticleChild[] = [];
    comments: EventComment[];
    allComments: any = [];
    commentsArray: any = [];
    materialEntity: any;
    featureValueEntity: any;
    articleProductionParameterEntity: any;
    articleEntity: any;
    articleOperationEntity: any;
    articleOperationId: any = [];
    eventsOfArticleId: any = [];
    totalQuantityBrutto: string = '0';
    totalQuantityNetto: string = '0';
    defaultCurrency: string = '€';
    totalPrice: Money;
    standardOperationsCost: number = 0;
    pdfArray: PromanFile[] = [];

    constructor(
        private Entity: Entity,
        private Dialog: Dialog,
        private Articles: ArticlesService,
        private Currencies: CurrenciesService,
        private Cookies: CookiesService,
        private route: ActivatedRoute,
        private FilePreview: FilePreviewService,
        public ACL: ACL,
    ) {
        if (this.route.parent) this.item = this.route.parent.snapshot.data['article'];
        this.materialEntity = this.Entity.get('material');
        this.featureValueEntity = this.Entity.get('feature_value');
        this.articleProductionParameterEntity = this.Entity.get('article_production_parameter');
        this.articleOperationEntity = this.Entity.get('article_operation');
        this.articleEntity = this.Entity.get('article');
        this.totalPrice = this.Currencies.getZeroPrice();
        this.defaultCurrency =this.totalPrice.currency;
    }

    ngOnInit() {
        this.Articles.initMaterials();

        this.Entity.get('article_child').search({ 'parent.id': this.item.id, 'join': ['child', 'child.categories'] })
            .then((response: ArticleChild[]) => this.subproducts = response);

        Promise.all([
                this.featureValueEntity.search({ 'articles.id': this.item.id, 'join': ['feature'] }),
                this.articleProductionParameterEntity.search({
                    'article.id': this.item.id,
                    'join': [
                        'parameter',
                        'children',
                        'children.parameter'
                    ],
                    'sort': { position: 'asc' }
                })
            ])
            .then((response: [FeatureValue[], ArticleProductionParameter[]]) => {
            const features = [];
            const enabledValues = response[0];
            const map = {};
            let featureId;

            for (let iter = 0; iter < enabledValues.length; iter++) {
                featureId = enabledValues[iter].feature.id;

                if (!map[featureId]) {
                    map[featureId] = enabledValues[iter].feature;
                }

                const feature = map[featureId];

                if (!feature.options) {
                    feature.options = [];
                }

                feature.options.push(enabledValues[iter]);

                enabledValues[iter].feature = null;
            }

            for (const iter in map) {
                features.push(map[iter]);
            }

            this.features = features;

            this.item.parameterGroups = [];
            this.item.parameters = [];

            response[1].forEach((parameter: any) => {

                if (parameter.parameter.type === 'parameter_group') {
                    try {
                        parameter.name = JSON.parse(parameter.value).name;

                        this.item.parameterGroups.push(parameter);
                    } catch (e) {

                    }

                } else {
                    this.item.parameters.push(parameter);

                }

            });
        });

        this.item.errors = [];

        if (!this.operations.length) {
            this.articleOperationEntity.search({ 'article.id': this.item.id, 'join': ['operation', 'files', 'article']})
                .then((operations: ArticleOperation[]) => {
                    this.operations = operations
                    this.operations.forEach((o) => {
                        this.standardOperationsCost += o.standardCost;
                    });
                });
        }

      this.Articles.currentMaterialSummary
        .subscribe((values) => {
          if (!values) return;
          const weightBrutto = {};
          const weightNetto = {};
          let price = this.Currencies.getZeroPrice();

          values.forEach((material) => {
            const unit = material.materialType.unit;

            if (!weightBrutto[unit]) {
              weightBrutto[unit] = 0;
              weightNetto[unit] = 0;
            }

            const quantity = (+material._quantity || 0.0);
            weightBrutto[unit] += quantity;
            weightNetto[unit] += +(quantity * (material.ratioBruttoNetto || 1) * (material.articleMaterials[material.articleMaterials.length - 1].article.id === this.item.id ?
              material.articleMaterials[material.articleMaterials.length - 1].finalCoefficient : 1));

            price = addMoney([price, material._price]);
          });

          this.totalPrice = price;

          let brutto = '';
          let netto = '';

          for (const unit of Object.keys(weightBrutto)) {
            brutto = brutto + `${+weightBrutto[unit].toFixed(4)} ${unit}<br>`;
            netto = netto + `${+weightNetto[unit].toFixed(4)} ${unit}<br>`;
          }

          this.totalQuantityBrutto = brutto;
          this.totalQuantityNetto = netto;
        });


        this.getCommentsOfArticle();
        this.pdfArray = this.item.files?.filter((file: PromanFile) => file.extension === 'pdf');
    }

    print = async () => {
        const entityParams = { context: 'technology_description' };
        const templates = await this.Entity.get('template').search(entityParams);

        if (!templates.length) return;

        const openPdf = (template?: string) => {
            const token = this.Cookies.get(COOKIE_AUTHORIZATION);

            let url = `${CONFIG.api}article/pdf?article=${this.item.id}&_authorization=${token}`;

            if (template) {
                url += `&template=${template}`;
            }

            window.open(url);
        };

        if (templates.length > 1) {
            this.Dialog
                .open(InputDialogComponent, {
                    header: 'template',
                    variant: 'confirm',
                    mainField: {
                        key: 'template',
                        name: 'template',
                        type: 'autocomplete',
                        value: templates[0],
                        config: { entity: 'template', entityParams },
                    }
                })
                .then((result: { template: string }) => openPdf(result.template));

        } else {
            openPdf();

        }

    };

    downloadPdfs() {
      this.pdfArray.forEach((file) => this.FilePreview.download(file));
    }

    getCommentsOfArticle() {
      this.Entity.get('event').search({join: ['articleOperation', 'articleOperation.article', 'comments'], 'articleOperation.article.id': this.item.id}).then((resp) => {
      resp.filter((eventOperation2) => eventOperation2.comments && eventOperation2.comments.length>0).forEach((eventOperation) => {
            this.eventsOfArticleId.push(eventOperation.id)
            this.comments = eventOperation.comments;
            this.commentsArray.push(this.comments);
        })
      }).then( () => {
        this.commentsArray.forEach((comm: any) => {
          comm.forEach((comment: any) => {
            this.allComments.push(comment);
          })
        })
      })

    }



}
