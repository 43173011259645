import { Injectable } from '@angular/core'
import { resourcesConfig } from '@proman/resources';
import { ACL } from '@proman/services/acl.service';
import { Entity } from '@proman/services/entity.service';
import { QueryExpressionService } from '@proman/services/query-expression.service';
import { FilterService } from '@proman/services/filter.service';
import moment from 'moment';
import { CurrUser, TableField } from '@proman/interfaces/object-interfaces';
import {
    Operation,
    Order,
    Production,
    ProductionOperation,
    ResourceBooking
} from '@proman/interfaces/entity-interfaces';
import { ProductionOperationEntityInterface } from '@proman/resources/production_operation';
import { safeMap } from '@proman/utils';
import { PromanStateService } from '@frontend/shared/services/proman-state.service';
import { ProductionEntityInterface } from '@proman/resources/production';
import { Store } from '@ngrx/store';
import { getCurrUser } from '@proman/store/curr-user';
import { ParametersService } from '@proman/parameters/services/parameters.service';

@Injectable()
export class ProductionOperationService {
    operationEntity: ProductionOperationEntityInterface;
    currUser: CurrUser;
    productionEntity: ProductionEntityInterface;

    constructor(
        private Filter: FilterService,
        private Entity: Entity,
        private Parameters: ParametersService,
        private PromanState: PromanStateService,
        private ACL: ACL,
        private QueryExpression: QueryExpressionService,
        private store: Store,
    ) {
        this.productionEntity = this.Entity.get('production');
        this.store.select(getCurrUser)
            .subscribe((value) => this.currUser = value);
        this.setEntity();
    }

    setEntity() {
        this.operationEntity = this.Entity.get('production_operation') as ProductionOperationEntityInterface;
    }

    getEntity = (): ProductionOperationEntityInterface => this.operationEntity;

    private static getNameValue(op: ProductionOperation) {
        let groupName;
        let name;
        let articleOperation = op.articleOperation;
        let segmentParameter = op.segmentParameter;

        if (articleOperation && articleOperation.operation.name) {
            name = articleOperation.operation.name;

        }

        if (segmentParameter) {
            groupName = JSON.parse(segmentParameter.value).name;

            if (groupName) {
                name += ' (' + groupName + ')';
            }
        }

        return name;
    }

    private concatOrdersProperty(prop: keyof Order) {

        return (event: ProductionOperation) => {

            if (event.production) {
                return event.production.order && event.production.order[prop] || '';

            } else if (event.order) {
                return event.order[prop];

            }

        };
    }

    getAggregated = (id: number, belongsToOrder?: boolean) => {
        return Promise.all([
            this.operationEntity
                .get({
                    'id': id,
                    'join': [
                        'articleOperation',
                        'articleOperation.operation',
                        'articleOperation.operation.parameters',
                        'articleOperation.operation.visibleMaterialCategories',
                        'resourceBookings',
                        'resourceBookings.workplace',
                        'resourceBookings.workplace.workgroup',
                        'resourceBookings.employee.photo',
                        'resourceBookings.childBookings',
                        'resourceBookings.childBookings.employee',
                        'resourceBookings.childBookings.employee.photo',
                        'supervisor.photo',
                        'segmentParameter',
                        'segmentParameter.articleProductionParameter',
                        'comments',
                        'order.files',
                        'resourceBookings.subcontractor.logo'
                    ],
                    'resourceBookings.parentBooking.id': this.QueryExpression.isNull(),
                    'partialJoin': {
                        'resourceBookings.subcontractor': ['id', 'name'],
                        'productionOperationParameters': ['id', 'value'],
                        'productionOperationParameters.parameter': ['id', 'name', 'type', 'value'],
                        'resourceBookings.employee': ['id', 'name'],
                        'comments.author': ['id', 'name'],
                        'supervisor': ['id', 'name'],
                        'order': ['id', 'name', 'number', 'customerNumber', 'managerComments', 'packagingRequirements'],
                        'order.manager': ['name'],
                        'order.customer': ['name'],
                        'order.shipments': ['id', 'date'],
                        'order.articles': ['id', 'name', 'altName'],
                        'tags': ['id', 'name', 'color'],
                        'order.tags': ['id', 'name', 'color'],
                        'order.type': ['id', 'name'],
                    },
                    'sort': { 'order.shipments': { date : 'asc' }, 'resourceBookings': { plannedStat: 'asc' }  }
                }),
                !belongsToOrder ? this.Entity.get('production')
                    .get({
                        'operations.id': id,
                        'join': [
                            'order.shipments',
                            'order.files',
                            'article.files',
                            'order.tags',
                            'tags',
                            'type',
                            'subtype',
                        ],
                        'partialJoin': {
                            'order': ['id', 'name'],
                            'order.type': ['id', 'name'],
                            'order.customer': ['name'],
                            'article': ['id', 'name', 'altName'],
                            'order.manager': ['id', 'name'],
                        }
                    })
                    .catch((response: any): any => {
                        return null;
                    }) : null,
            ])
            .then((response: [ProductionOperation, Production]) => {
                if (!response[1]) response[1] = null as Production;
                response[0].resourceBookings.sort((a, b) => a.plannedStart < b.plannedStart ? -1 : 1);
                response[0].production = response[1];
              (response[0] as any).orderType = response[1]?.order.type?.name;
                return response[0] as ProductionOperation;
            })
            .catch(() => {
                if (!belongsToOrder) this.PromanState.to('Events');
            });
    };

    isPersonalEvent(event: any, employeeId: number) {
        let result: boolean = false;

        if (event.resourceBookings) {

            event.resourceBookings.forEach((booking: any) => {

                if (booking.employee && employeeId === booking.employee.id) {
                    result = true;

                }

                if (booking.workplace && booking.childBookings) {

                    booking.childBookings.forEach((childBooking: any) => {

                        if (childBooking.employee && employeeId === childBooking.employee.id) {
                            result = true;

                        }

                    });

                }

            });

        }

        return result;
    }

    isSupervisedEvent = (event: ProductionOperation, employeeId: number) => {
        return event.supervisor?.id === employeeId;
    };

    showOptions = () => {
        return this.canUpdateEvent || this.ACL.check('event.update_status');
    };

    canUpdateWorkplace = () => {
        return this.canUpdateEvent;
    };

    canUpdateEmployees = () => {
        return this.ACL.check('event.update_status');
    };

    canUpdateTime = () => {
        return this.ACL.check('event.update_status');
    };

    canUpdateSupervisor = () => {
        return this.ACL.check('event.update_status');
    };

    canUpdateEvent = () => {
        return this.ACL.check('event.edit');
    };

    canUpdateParameters = (event: any, employeeId: number) => {
        return (this.isPersonalEvent(event, employeeId) ||
            this.canUpdateEvent) && event.status === this.operationEntity.STARTED;
    };

    showItemQuantities = (event: any) => {
        return event.articleOperation.productionStoreAccess &&
            (event.status === this.operationEntity.STARTED ||
                event.status === this.operationEntity.FINISHED);
    };

    canUpdateItemQuantities = (event: any) => {
        return event && event.articleOperation && event.articleOperation.operation.productionStoreAccess &&
            (event.status === this.operationEntity.STARTED || event.status === this.operationEntity.FINISHED);
    };

    canStart = (event: ResourceBooking, production: Production  ) => {
        return (production ? production.status === this.productionEntity.STARTED : true) && // if not production operation
            event.status === this.operationEntity.UNSTARTED ||

            event.status === this.operationEntity.WAITING_FOR_PREVIOUS ||

            (event.type === 'workplace' && event.status === this.operationEntity.STARTED &&
                (event.parallelity !== undefined) && event.parallelity) ||

            (event.type === 'workplace' && event.status === this.operationEntity.FINISHED &&
                (event.parallelity !== undefined) && event.parallelity && production?.status !== this.productionEntity.COMPLETE);
    };

    canEnd = (booking: any, operation?: any) => {
        let parametersDone = true;
        let productionOperationParameters = operation && operation._parameters;

        if (productionOperationParameters && productionOperationParameters.length &&
            productionOperationParameters.filter((item: any) => item.parameter.required).length) {
                parametersDone = !!productionOperationParameters
                    .filter((item: any) => item.parameter.required)
                    .filter((item: any) => !this.Parameters.empty(item))
                    .length;

        }

        return ['started'].includes(booking.status) && parametersDone;
    };

    canCancel = (event: any) => {
        return event.status === this.operationEntity.STARTED;
    };

    canComment = () => {
        return true;
    };

    showComments = (event: any) => {
        return (
            event.status === this.operationEntity.STARTED ||
            event.status === this.operationEntity.FINISHED ||
            event.status === this.operationEntity.CANCELED ||
            event.status === this.operationEntity.UNCONFIRMED
        );
    };

    canUpdateFiles = (event: any) => {
        return event.status === this.operationEntity.STARTED;
    };

    canConfirm = (event: ProductionOperation, employeeId: number) => {
        return (event.status === this.operationEntity.UNCONFIRMED || event.status === this.operationEntity.STARTED) &&
            (this.isSupervisedEvent(event, employeeId) || this.canUpdateEvent());
    };

    canRevertStatus = (event: any) =>  event.status !== this.operationEntity.UNSTARTED;

    getWorkplaceOperation = (workplaceId: any) => {
        return this.operationEntity
            .search({
                'resourceBookings.plannedStart': this.QueryExpression.to(moment()),
                'resourceBookings.plannedEnd': this.QueryExpression.from(moment()),
                'resourceBookings.workplace.id': workplaceId,
                'select': ['name'],
                'partialJoin': {
                    'resourceBookings': ['plannedStart', 'plannedEnd', 'status'],
                    'resourceBookings.childBookings': ['plannedStart', 'plannedEnd'],
                    'resourceBookings.childBookings.employee': ['name'],
                    'segmentParameter': ['value'],
                    'production': ['name', 'quantity'],
                    'order': ['name']
                },
                'join': ['resourceBookings.childBookings.employee.photo']
            });
    };

    getNextWorkplaceOperation = (workplaceId: number) => {
        return this.operationEntity
            .search({
                'resourceBookings.plannedStart': this.QueryExpression.from(moment()),
                'resourceBookings.workplace.id': workplaceId,
                'select': ['name'],
                'partialJoin': {
                    'resourceBookings': ['id', 'plannedStart', 'plannedEnd', 'status'],
                    'resourceBookings.childBookings': ['id', 'plannedStart', 'plannedEnd'],
                    'resourceBookings.childBookings.employee': ['id', 'name'],
                    'segmentParameter': ['id', 'value'],
                    'production': ['id', 'name'],
                    'order': ['id', 'name']
                },
                'join': ['resourceBookings.childBookings.employee.photo'],
                'paginate': false,
                'limit': 1,
                'sort': { 'resourceBookings.plannedStart': 'asc' }
            });
    };

    getExtraParameters = resourcesConfig['production_operation'].params.extraParameters;

    getStatusField = (): TableField => {
        const strict = this.QueryExpression.orStrict;

        return {
            name: 'status',
            key: 'status',
            translate: true,
            filter: {
                type: 'dropdown',
                options: [
                    {
                        name: this.Filter.translate('booked'),
                        id: strict([this.operationEntity.BOOKED])
                    },
                    {
                        name: this.Filter.translate('unstarted'),
                        id: strict([this.operationEntity.UNSTARTED])
                    },
                    {
                        name: this.Filter.translate('started'),
                        id: strict([this.operationEntity.STARTED])
                    },
                    {
                        name: this.Filter.translate('finished'),
                        id: strict([this.operationEntity.FINISHED])
                    },
                    {
                        name: this.Filter.translate('canceled'),
                        id: strict([this.operationEntity.CANCELED])
                    },
                    {
                        name: this.Filter.translate('unconfirmed'),
                        id: strict([this.operationEntity.UNCONFIRMED])
                    }
                ],
                key: 'id'
            }
        };
    };

    getFields = (operations: Operation[]): TableField[] => {
        return [
            {
                name: 'planned_start',
                key: 'plannedStart',
                formatter: 'dateTime',
                formatterConfig: '_datetime_js'
            },
            {
                name: 'planned_end',
                key: 'plannedEnd',
                formatter: 'dateTime',
                formatterConfig: '_datetime_js'
            },
            {
                name: 'operation',
                key: 'articleOperation.operation.id',
                getValue: ProductionOperationService.getNameValue,
                filter: {
                    type: 'dropdown_multi',
                    options: operations,
                    showSearch: true
                },
                extraPartialJoins: {
                  'articleOperation': ['id'],
                  'articleOperation.operation': ['name'],
                }
            },
            {
                name: 'production',
                key: 'production.id',
                getValue: (row: ProductionOperation) =>
                    (row.production ? row.production.id + ' - ' + row.production.name : ''),
                state: {
                    name: 'Production',
                    key: 'productionId',
                    id: 'production.id',
                    acl: 'production.edit',
                },
                extraPartialJoins: {
                  'production': ['id', 'name'],
                }
            },
            {
                name: 'article',
                key: 'articleOperation.article.name',
                extraPartialJoins: {
                    'articleOperation': ['id'],
                    'articleOperation.article': ['id', 'name'],
                }
            },
            {
                name: 'article_categories',
                key: 'articleOperation.article.categories.name',
                getValue: (event: ProductionOperation) => event.articleOperation?.article && safeMap(event.articleOperation?.article?.categories, 'name').join(', ') || '',
                extraPartialJoins: {
                    'articleOperation': ['id'],
                    'articleOperation.article.categories': ['id', 'name'],
                }
            },
            {
                name: 'order_number',
                key: 'production.order.number',
                getValue: this.concatOrdersProperty('number'),
                extraPartialJoins: {
                  'production': ['name', 'quantity', 'id'],
                  'production.order': ['number'],
                }
            },
            {
                name: 'order_name',
                key: 'production.order.name',
                getValue: this.concatOrdersProperty('name'),
                extraPartialJoins: {
                  'production.order': [ 'name'],
                }
            },
            {
                name: 'desired_dispatch_date',
                key: 'production.order.desiredDispatchDate',
                getValue: this.concatOrdersProperty('desiredDispatchDate'),
                formatter: 'dateTime',
                extraPartialJoins: {
                  'production.order': ['desiredDispatchDate'],
                }
            },
            {
                name: 'customer',
                key: 'production.order.customer.name',
                getValue: (row: ProductionOperation) => row.order?.customer?.name || row.production?.order?.customer?.name || '',
                extraPartialJoins: {
                    'production.order.customer': ['name'],
                }
            },
            {
                name: 'customer_number',
                key: 'production.order.customerNumber',
                getValue: this.concatOrdersProperty('customerNumber'),
                extraPartialJoins: {
                    'production.order': ['customerNumber'],
                }
            },
            {
                name: 'production',
                key: 'production.name',
                extraPartialJoins: {
                    'production': ['name'],
                }
            },
            {
                name: 'quantity',
                key: 'production.quantity'
            },
            this.getStatusField()
        ];
    };

    async endMultiple(operations: number[]) {
        await this.operationEntity.endMultiple({ ids: operations });
    }

    async startMultiple(operations: number[]) {
        await this.operationEntity.startMultiple({ ids: operations });
    }

}
