import { Component, Input, Output, EventEmitter, SimpleChanges, OnInit, OnChanges } from '@angular/core';
import { Entity, EntityNameType } from '@proman/services/entity.service';
import { mapId, removeByProperty } from '@proman/utils';
import { QueryExpressionService } from '@proman/services/query-expression.service';
import { ParametersOptionsService } from '@proman/services/parameters-options.service';
import { PromanStateService } from '../services/proman-state.service';
import { CustomerEmployee, Employee } from '@proman/interfaces/entity-interfaces';

@Component({
    selector: 'pm-contacts-manager',
    template: `
        <div class="ContactsManager">
            <div style="overflow: hidden; position: relative; height: 55px;">
                <div class="horizontal-slide"
                     fxLayout="row"
                     fxLayoutAlign="start center"
                     *ngIf="!isAddContactMode">
                    <pro-label *ngIf="config.label">{{ config.label | translate }}</pro-label>
                    <pro-btn *ngIf="!disabled"
                            (onClick)="addContact()"
                            icon="user-plus"
                            theme="accent"
                            [tooltip]="'add' | translate"
                            [tooltipPosition]="'right'"
                    ></pro-btn>
                </div>
                <div class="horizontal-slide"
                     fxLayout="row"
                     fxLayoutAlign="start center"
                     *ngIf="isAddContactMode">
                    <pro-autoc [config]="{ label: 'contact_person', autofocus: true, cache: false }"
                              [value]="contact"
                              [options]="options"
                              (onSearch)="searchContacts($event)"
                              (onChange)="setContact($event)" fxFlex></pro-autoc>
                    <pro-btn (click)="cancelAddContact()"
                            [label]="'cancel' | translate"
                            theme="primary"></pro-btn>
                </div>
            </div>
            <hr>

            <pro-no-records *ngIf="!localValue.length"></pro-no-records>
            <mat-list>
                <div class="animated-list"
                     *ngFor="let contact of localValue">
                    <mat-list-item>
                        <span class="RightPadding" matListAvatar>
                            <fa *ngIf="!contact.photo" name="user-circle" [size]="'3x'"></fa>
                            <pro-thumbnail *ngIf="contact.photo" [file]="contact.photo "></pro-thumbnail>
                        </span>


                        <h4 *ngIf="contact.name" mat-line>
                             {{ contact.name }} <ng-container *ngIf="contact.customer && !contact.name.endsWith(contact.customer.alias)"> {{ ' - ' + contact.customer.alias }} </ng-container> </h4>
                        <p *ngIf="contact.email || contact.phone" mat-line>
                            <span *ngIf="contact.phone">{{ contact.phone }} </span><span *ngIf="contact.email">{{ contact.email }}</span>
                        </p>
                        <div fxLayout="row" fxLayoutAlign="end center">
                            <pro-btn *ngIf="entity && entityType"
                                     (onClick)="handleNotification(contact)"
                                     [disabled]="contact.notified"
                                     icon="bell"
                                     size="1p3x"
                                     theme="accent"
                                     [tooltip]="'send_notification' | translate"
                            ></pro-btn>
                            <pro-btn *ngIf="contact.hasLink"
                                     (onClick)="goToEmployee(contact)"
                                     icon="chevron-double-right"
                                     size="1p3x"
                                     theme="accent"
                                     [tooltip]="'go_to_employee' | translate"
                            ></pro-btn>
                            <pro-btn *ngIf="!disabled"
                                     (onClick)="removeContact(contact)"
                                     icon="times"
                                     size="1p3x"
                                     theme="warn"
                                     [tooltip]="'delete_contact' | translate"
                            ></pro-btn>
                        </div>
                    </mat-list-item>
                </div>
            </mat-list>
        </div>
    `
})

export class ContactsManagerComponent implements OnInit, OnChanges {
    @Input() config: any;
    @Input() value: any;
    @Input() disabled: any;
    @Input() searchParams: any;
    @Input() options: any;
    @Input() onSearch: any;
    @Input() entity: { id: number };
    @Input() entityType: EntityNameType;
    @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() onAdd: EventEmitter<any> = new EventEmitter<any>();
    @Output() onRemove: EventEmitter<any> = new EventEmitter<any>();
    SearchEntity: any;
    isAddContactMode: any;
    localValue: any;
    mainEntity: any;
    contact: any;

    constructor(
        private promanState: PromanStateService,
        private Entity: Entity,
        private QueryExpressionService: QueryExpressionService,
        private ParametersOptions: ParametersOptionsService,
        private QueryExpression: QueryExpressionService,
    ) {

    }

    ngOnInit() {

        if (this.config.searchEntity) {
            this.SearchEntity = this.Entity.get(this.config.searchEntity);

        }

        if (this.config.mainEntity) {
            this.mainEntity = this.Entity.get(this.config.mainEntity);

        }

        this.setLocalValue(this.value);
    }

    ngOnChanges(changes: SimpleChanges) {

        if (changes.value) this.setLocalValue(changes.value.currentValue);

    }

    setLocalValue(value: any) {
        this.localValue = value || [];

        this.getEmployeeData();
    }

    async handleAddContact(contact: any) {
        setTimeout(() => this.isAddContactMode = false);

        if (this.mainEntity || this.config.postRequest) {
            this.localValue.push(contact);
            await this.addItem(contact);

        }

        if (this.onAdd.observers.length) this.onAdd.emit(contact);

        const result = this.config.emitMapped ? this.localValue.map(mapId) : this.localValue;

        this.onChange.emit(result);
        this.getEmployeeData();

    }

    addItem(value: any) {
        const data = Object.assign({}, this.config.mainParams);

        if (this.config.isCreate) return;

        if (this.config.postRequest) {
            data[this.config.key] = [value.id];

            return this.mainEntity.postRequest('add_' + this.config.postRequest, data);

        } else {
            data[this.config.key] = value.id;
            return this.mainEntity.addAssociation(data);

        }

    }

    removeItem(value: any) {
        const data = Object.assign({}, this.config.mainParams);

        if (this.config.isCreate) {
            return;
        }

        if (this.config.postRequest) {
            data[this.config.removeKey] = [value.id];

            return this.mainEntity.postRequest('remove_' + this.config.postRequest, data);

        } else {
            data[this.config.key] = value.id;

            return this.mainEntity.removeAssociation(data);

        }

    }

    handleRemoveContact(contact: any) {

        if (this.mainEntity || this.config.postRequest) {
            this.removeItem(contact);
            removeByProperty(this.localValue, contact, 'id');

        }

        if (this.onRemove.observers.length) {
            this.onRemove.emit(contact);

        }

    }

    getUsedIds(items: any) {
        const output = [];

        for (const item of items) {
            output.push(item.id);

        }

        return output;
    }

    searchContacts(query: string) {
        let usedIds = [];
        let params: any;

        if (!this.config.searchEntity) {
            this.options = this.onSearch(query);

        } else {

            if (this.localValue) usedIds = this.getUsedIds(this.localValue);

            params = {
                name: query,
                join: ['photo']
            };

            if (usedIds.length) params.id = this.QueryExpressionService.notIn(usedIds);

            if (this.config.searchParams) params = Object.assign({}, params, this.config.searchParams);

            this.options = this.SearchEntity.search(params);
        }
    }

    addContact() {
        this.isAddContactMode = true;
    }

    setContact(value: any) {
        this.handleAddContact(value);
    }

    removeContact(value: any) {
        this.handleRemoveContact(value);
    }

    cancelAddContact() {
        this.isAddContactMode = false;
    }

    goToEmployee = (person: any) => {

        if (person._customerEmployee) {
            this.promanState.to('CustomerEmployee', { employeeId: person.id, customerId: person._customerEmployee.customer.id });
        }

        if (person._employee) {
            this.promanState.to('Employee', { employeeId: person.id });
        }

    };

    getEmployeeData = () => {
        const ids = this.localValue.map(mapId);
        if (ids?.length) Promise.all([
                this.ParametersOptions
                    .search({
                        entity: 'employee',
                        entityParams: { id: this.QueryExpression.in(ids) }
                    }),
                this.ParametersOptions
                    .search({
                        entity: 'customer_employee',
                        entityParams: { id: this.QueryExpression.in(ids), join: ['customer'] }
                    })
            ])
            .then((values: [Employee[], CustomerEmployee[]]) => {
                if (values[0]) {
                    values[0].forEach((item) => {
                       for (const person of this.localValue) {
                           if (person.id === item.id) {
                               person._employee = item;
                               person.hasLink = true;
                               break;
                           }

                       }
                    });
                }
                if (values[1]) {
                    values[1].forEach((item) => {
                        for (const person of this.localValue) {
                            if (person.id === item.id) {
                                person.customer = item.customer;
                                person._customerEmployee = item;
                                person.hasLink = true;
                                break;
                            }

                        }
                    });
                }
            });
    };

    handleNotification(contact: { id: number, notified: boolean }) {
        contact.notified = true;
        this.Entity.get(this.entityType).sendNotification({ id: this.entity.id, person: contact.id });
    }

}
