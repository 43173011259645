import {
  Component, AfterViewInit, Input, ElementRef, ViewChild, Output, EventEmitter,
  SimpleChanges, OnChanges, OnInit,
} from '@angular/core';
import { Entity, EntityNameType } from '@proman/services/entity.service';
import { FilterService } from '@proman/services/filter.service';
import { dateTime } from '@proman/interfaces/common.interface';
import { Store } from '@ngrx/store';
import { CurrUser } from '@proman/interfaces/object-interfaces';
import { getCurrUser } from '@proman/store/curr-user';
import { findByProperty } from '@proman/utils';
import { PromanFile } from '@proman/interfaces/entity-interfaces';

const REACTIONS = [
  'thumbs-up',
  'heart',
];

declare interface TimelineChatReaction {
  icon: string;
  users: string[];
  isMyReaction?: boolean;
}

interface TimelineMessage {
  id: number;
  createdAt: dateTime;
  updatedAt: dateTime;
  type: 'person_email'|'message'|'git_commit';
  content: {
    author: string;
    author_id: number;
    message: string;
    commit_link: string;
    commit_branch: string;
  };
  internal: boolean;
  isEdited?: boolean;
  isEditMode?: boolean;
  preview?: string;
  isPreview?: boolean;
  newId?: string;
  file?: PromanFile;
  reactions: TimelineChatReaction[];
}

@Component({
  selector: 'pm-timeline-chat',
  template: `
    <div class="TimelineChat Padding"
         #element
         fxLayout="column">
      <hr>
      <div class="TimelineChat-Messages"
           fxLayout="column"
           fxLayoutGap>
        <div class="TimelineChat-Messages--Container">
          <div *ngFor="let item of history; let $last = last"
               class="TimelineChat-Messages--Container---Message">
            <div class="TimelineChat-Messages--Container---Message----Header"
                 fxLayout="row"
                 fxLayoutAlign="space-between center">
              <div fxLayout="row" fxLayoutAlign="start center">
                <fa *ngIf="item.type === 'message'" [ngClass]="{ 'accent': item.internal }" name="comment-lines" ></fa>

                <span class="Icon" *ngIf="item.type === 'git_commit'" >
                    <img src="assets/images/gitlab-logo-500.svg">
                </span>

                <span class="Icon" *ngIf="item.type === 'person_email'" >
                    <img src="assets/images/icon_gmail.png" alt="google_mail">
                </span>
                <pro-thumbnail *ngIf="item.file"
                               [file]="item.file"
                               [size]="20"
                               [preventView]="false"
                               class="LeftMargin"></pro-thumbnail>
                <span class="LeftMargin">{{ item.content?.author }}</span>
              </div>
              <div fxLayout="row"
                   fxLayoutAlign="end center">
                <div class="TimelineChat-ReactionsContainer">
                  <ng-container [ngTemplateOutlet]="messageReactionTemplate" [ngTemplateOutletContext]="{ item: item }"></ng-container>
                </div>
                <fa *ngIf="item.type !== 'git_commit'"
                    name="reply"
                    faClass="fas"
                    class="TimelineChat--ReplyButton" (click)="handleReply(item)"></fa>
                <ng-container [ngSwitch]="item.isEdited">
                  <i *ngSwitchCase="true">{{ 'edited' | translate }}: {{ item.updatedAt | proDateTime }}</i>
                  <i *ngSwitchDefault>{{ item.createdAt | proDateTime }}</i>
                </ng-container>
                <div class="Btn-actions"
                     [hidden]="!item.isEditMode">
                  <pro-btn
                    (click)="editMessage(item)"
                    icon="edit"
                    [tooltip]="'edit_message' | translate"
                    theme="primary"></pro-btn>
                  <pro-btn  *ngIf="currUser.isEmployee && this.internalMode === true"
                            (click)="setInternal(item)"
                            icon="comment-lines"
                            [tooltip]="'toggle_internal' | translate"
                            [theme]="item.internal ? 'accent' : 'primary'"></pro-btn>
                  <pro-btn
                    (click)="deleteMessage(item)"
                    icon="trash"
                    [tooltip]="'delete_message' | translate"
                    theme="warn"></pro-btn>
                </div>
              </div>
            </div>
            <div *ngIf="item.replyTo"
                 class="TimelineChat-Messages--Container---Message----Reply"
                [innerHTML]="item.replyTo"></div>
            <ng-container [ngSwitch]="item.type">
              <div *ngSwitchCase="'git_commit'"
                   class="TimelineChat-Messages--Container---Message----Text">
                <a [href]="item.content?.commit_link" target="_blank">
                  {{ item.content?.message }}</a>
              </div>
              <div *ngSwitchDefault class="TimelineChat-Messages--Container---Message----Text"
                   (click)="handleMessageClick(item, $last)"
                   [innerHTML]="item.type === 'person_email' && item.isPreview ? item.preview : item.content?.message | proUrlClickable "></div>
            </ng-container>
            <hr [hidden]="$last">
          </div>
        </div>
      </div>
      <div *ngIf="replyTo"
           class="ReplyToContainer">
        <fa name="comments" faClass="fas"></fa>
        <span [innerHTML]="replyTo"></span>
        <fa name="times" (click)="cancelReply()"></fa>
      </div>
      <div class="TimelineChat-Input"
           fxLayout="row"
           fxLayoutAlign="start end"
           #input>
        <pm-txt [value]="newMessage"
                [config]="{ label: 'message', type: 'textarea', preventNewLine: true, debounce: 0, emoji: true }"
                (onChange)="setMessage($event)"
                (keydown.enter)="send()"
                fxFlex></pm-txt>
        <pro-btn
          (click)="send()"
          [icon]="isEditMode ? 'check-square' : 'paper-plane'"
          [pending]="pending"
          theme="accent"
          [tooltip]="'send' | translate"
        ></pro-btn>
      </div>
    </div>

    <ng-template #messageReactionTemplate let-item="item">
      <ng-container *ngIf="item.reactions?.length === 0">
        <fa name="face-smile-plus" class="Empty"></fa>
      </ng-container>

      <div class="TimelineChat-ReactionsContainer_AddReaction" [ngStyle]="{ width: (messageReactions.length * 27) + 'px' }">
        <fa *ngFor="let reaction of messageReactions" [name]="reaction" (click)="handleReaction(item, reaction)"></fa>
      </div>

      <div class="TimelineChat-ReactionsContainer_ReactionContainer"
           *ngFor="let reaction of item.reactions">
        <fa [name]="reaction.icon" [ngClass]="{ 'MineReaction': reaction.isMyReaction }"></fa>
        <span *ngIf="reaction.users?.length > 1">{{ reaction.users.length }}</span>
      </div>
    </ng-template>
  `,
  styles: [
    `
      .Icon img {
        width: 24px;
      }

      fa.accent {
        color: #2196f3;
      }

      .TimelineChat-ReactionsContainer {
        margin-right: 8px;
        cursor: pointer;
        position: relative;
        display: flex;
        flex-direction: row;

        &> div {
            margin-right: 8px;
       }

      fa[name="face-smile-plus"].Empty {
        color: #b9b9b9;
      }

      }

      .TimelineChat-ReactionsContainer_AddReaction {
        display: none;
        position: absolute;
        top: -7px;
        right: 14px;
        float: right;
        direction: rtl;
        background: white;
        border: 2px solid #4c96d2;
        border-radius: 2px;
        padding: 2px;
        box-sizing: border-box;
        z-index: 1;

      fa {
        margin: 0 2px;

      &:hover {
         color: orange;
       }
      &:focus {
         color: red;
       }

      }
      }

      .TimelineChat-ReactionsContainer:hover .TimelineChat-ReactionsContainer_AddReaction {
        display: block;
      }

      .TimelineChat-ReactionsContainer_ReactionContainer {
        position: relative;

      fa.MineReaction {
        color: #2196f3;
      }

      span {
        position: absolute;
        bottom: -8px;
        right: -8px;
      }
      }

      .TimelineChat--ReplyButton {
        color: #b9b9b9;
        margin-right: 8px;
        cursor: pointer;
      }

      .ReplyToContainer {
        position: relative;
        padding: 4px 16px;
        color: #666;
        border-top: 1px solid grey;
        border-bottom: 1px solid grey;

       fa {
            color: #b9b9b9;
            margin-left: 8px;
            margin-right: 8px;
        }

       fa[name="times"] {
         color: #f44336;
         position: absolute;
         right: 0;
         top: 0;
         padding: 6px;
         cursor: pointer;
       }
      }

    `
  ]
})
export class TimelineChatComponent implements AfterViewInit, OnChanges {
  @Input() entity: { id: number };
  @Input() entityType: EntityNameType;
  @Input() internalMode: boolean = false;
  @ViewChild('element', { static: true }) element: ElementRef;
  @ViewChild('input', { static: true }) input: ElementRef;
  params: any;

  timelineEntity: any;
  timelineMsgEntity: any;
  newMessage: string = '';
  history: TimelineMessage[] = [];
  isEditMode: boolean = false;
  pending: boolean = false;
  editableMessage: any;
  currUser: CurrUser;
  messageReactions: string[] = REACTIONS;
  replyTo: string = '';

    constructor(
        Entity: Entity,
        private Filter: FilterService,
        private store: Store,
    ) {
        this.store.select(getCurrUser)
            .subscribe((value) => this.currUser = value);
        this.timelineEntity = Entity.get({ name: 'timeline', get: ['formatted'] });
        this.timelineMsgEntity = Entity.get('timeline_message');
    }

    ngOnChanges(changes: SimpleChanges) {
        let entity = changes.entity;

    if (entity && !entity.firstChange) {
      this.ngAfterViewInit();
    }

  }

  ngAfterViewInit() {
    this.params = {
      entityType: this.entityType,
      entityId: this.entity.id
    };

    if (this.internalMode && !this.currUser.isEmployee) {
      Object.assign(this.params, {internal: false});
      this.internalMode = false;
    } else if (this.internalMode && this.currUser.isEmployee)
    {
      Object.assign(this.params, {internal: true});
      this.internalMode = true;
    }
    this.loadHistory();
  }

  toggleEditMode(message: TimelineMessage, last: boolean) {
    if (last) message.isEditMode = !message.isEditMode;
  }

  editMessage(message: TimelineMessage) {
    let element = this.element.nativeElement;

    this.isEditMode = !this.isEditMode;

    if (this.isEditMode) {
      this.newMessage = message.content.message;
      this.editableMessage = message;
      element.querySelector('.TimelineChat-Input input')?.focus();

    } else {
      this.newMessage = null;
      element.querySelector('.TimelineChat-Input input')?.blur();
    }

  }

  deleteMessage(message: TimelineMessage) {
    this.timelineMsgEntity
      .remove({ id: message.id })
      .then(this.loadHistory);
  }

  setInternal(message: TimelineMessage) {
    this.timelineMsgEntity.update({ id: message.id, internal: !message.internal }).then(() => {
      this.loadHistory();
    })
  }

  async send(secondaryCheck: boolean = false) {

    this.pending = false;

    if (!(this.newMessage && this.newMessage.length)) {

      if (!secondaryCheck) await setTimeout(() => this.send(true), 300);

      return;

    }

    if (this.isEditMode) {
      this.editableMessage.message = this.newMessage;

      this.timelineMsgEntity
        .update({
          id: this.editableMessage.id,
          message: this.newMessage
        })
        .then(() => {
          this.loadHistory();
          this.isEditMode = false;
          this.sendSuccess();
        });

    } else {
      this.timelineMsgEntity
        .create(Object.assign({},
          { message: this.newMessage },
          this.replyTo ? { replyTo: this.replyTo } : {},
          this.params)
        )
        .then(() => {
          this.sendSuccess();
          this.loadHistory();
        })
        .catch(this.sendSuccess);

    }

    this.sendSuccess();

  }

  sendSuccess = () => {
    this.newMessage = '';
    this.replyTo = '';
  }

  setMessage = (message: string) => this.newMessage = message;

  setScrollPosition() {
    setTimeout(() => {
      let messagesContainerElement: any = document.querySelectorAll('.TimelineChat-Messages--Container');

      if (messagesContainerElement[0]) {
        messagesContainerElement[0].scrollTop = messagesContainerElement[0].scrollHeight;

      }

    });
  };

  loadHistory = () => {
    const userId = this.currUser.id.toString();

    this.timelineEntity
      .formatted(this.params)
      .then((response: TimelineMessage[]) => {
        if (!response) return;

        this.history = response.map((item ) => {
          if (item.updatedAt && item.updatedAt !== item.createdAt) item.isEdited = true;

          if (item.type === 'person_email') {
            item.preview = this.Filter.maxLength(item.content.message, 35);
            item.isPreview = true;

          }

          item.reactions.filter((react) => typeof react !== 'string')
            .forEach((react) => {

              if (react.users?.includes(userId)) {
                react.isMyReaction = true;
              }
            });

          item.reactions = item.reactions.filter((react) => typeof react !== 'string');

          if (item.newId) item.file = { newId: item.newId } as PromanFile;

          return item;
        });

        this.setScrollPosition();
      });
  };

    handleMessageClick(item: TimelineMessage, isLast: boolean) {
        const isMyMessage = item.content.author_id === this.currUser.person.id;

        if (item.type === 'person_email') {
            item.isPreview = !item.isPreview;

        } else if (isMyMessage) {
            this.toggleEditMode(item, isLast);

        }

    }

  handleReaction(message: TimelineMessage, reactionIcon: string){
    const userId = this.currUser.id.toString();
    const hasMyReaction = (message.reactions || []).find((_reaction) => _reaction.users?.includes(userId));
    const hasThisReaction = !!(message.reactions || []).find((_reaction) => { return _reaction.icon.match(reactionIcon) });

    const removeReaction = (_icon: string): TimelineChatReaction[] => {
      let reactions: TimelineChatReaction[] = message.reactions;

      reactions = reactions.map((_reac) => {
        if (_reac.icon === _icon) {
          _reac.users = _reac.users?.filter((_userId) => _userId !== userId);

          if (_reac.users?.length === 0) {
            return null;
          }
          return _reac;
        } else {
          return _reac;
        }
      })
        .filter((_reac) => !!_reac);

      return reactions;
    };
    const addReaction = (reactions: TimelineChatReaction[], _icon: string): TimelineChatReaction[] => {
      const thisReaction: TimelineChatReaction|undefined  = findByProperty<TimelineChatReaction>(reactions, 'icon', reactionIcon);
      if (thisReaction) {
        if (!thisReaction.users) { thisReaction.users = [userId]; }

        thisReaction.users.push[userId];
      } else {
        reactions.push({ icon: reactionIcon, users: [userId] });

      }

      return reactions;
    };

    if (hasMyReaction) {
      if (hasThisReaction) {
        let reactions = removeReaction(reactionIcon);

        this.timelineMsgEntity
          .update({ id: message.id, reactions: reactions.length ? reactions : "-" })
          .then(() => message.reactions = reactions);

      } else {
        let reactions = message.reactions || [];
        removeReaction(hasMyReaction.icon);
        reactions = addReaction(reactions, reactionIcon);

        this.timelineMsgEntity
          .update({ id: message.id, reactions })
          .then(() => message.reactions = reactions.map((_react) => {
            if (_react.icon === reactionIcon) {
              _react.isMyReaction = true;
            }

            return _react;
          }));


      }


    } else {
      let reactions = message.reactions || [];

      addReaction(reactions, reactionIcon);

      this.timelineMsgEntity
        .update({ id: message.id, reactions })
        .then(() => message.reactions = reactions.map((_react) => {
          if (_react.icon === reactionIcon) {
            _react.isMyReaction = true;
          }

          return _react;
        }));
    }
  }

  handleReply(item: TimelineMessage) {
   console.log('handleReply', item);
   this.replyTo = `<b>${item.content.author}: </b> ${item.content.message}`;
   this.element.nativeElement.querySelector('.TimelineChat-Input input')?.focus();

  }

  cancelReply() {
      this.replyTo = '';
  }

}

