import { Component, Input, OnChanges } from '@angular/core';
import { ImagePathService } from '@proman/services/image-path.service';
import { PromanFile } from '@proman/interfaces/entity-interfaces';

const DEFAULT_OPTIONS = {
    width: 150,
    height: 150
};

@Component({
    selector: 'pm-image',
    template: `
        <img [src]="imageUrl" onerror="this.onerror=null;this.src='assets/images/placeholder.png';" [ngStyle]="{ width: (options && options.width || 150) + 'px' }" [ngClass]="{ 'ObjectFitCover': options?.fit }">
    `,
    styles: [`
    
        .ObjectFitCover {
            object-fit: cover !important;  
        }
    `]
})

export class ImageComponent implements OnChanges {
    @Input() image: PromanFile;
    @Input() options: {
        width?: number;
        height?: number;
        path?: boolean;
        fit?: boolean;
    };

    imageUrl: string;

    constructor(
        private ImagePath: ImagePathService
    ) {

    }

    ngOnChanges() {

        if (this.image) {
            this.imageUrl = this.ImagePath.getFile(this.image, 'png');
        }

    }
}
