import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { isChef, isLocalhost, isProman, isSmarton } from '@proman/utils';

const DEFAULT_USER_PLACEHOLDER = 'assets/images/placeholder.png';
const PROMAN_LOGO_PLACEHOLDER = 'assets/images/proman_logo_white.png';
const SMARTON_LOGO_PLACEHOLDER = 'assets/images/smarton_logo_notext.png';
const LOCALHOST_LOGO_PLACEHOLDER = 'assets/images/icon-512x512.png';

@Component({
    selector: 'pm-user-thumb',
    template: `
        <div class="UserThumbnail" fxLayout="column" fxLayoutAlign="center center">
            <img *ngIf="thumb"
                 [src]="thumb | proThumbPath"
                 #img
                 (error)="handleError($event)"
                 style="size: 50px;height: 50px;"
            />
            <img *ngIf="!thumb && isUserThumb"
                 [src]="placeholderUrl"
                 #img
                 (error)="handleError($event)"
                 style="size: 50px;height: 50px;"
            />
              <fa name="user-circle" [size]="'4x'" style="color: whitesmoke; padding: 8px"  *ngIf="!(thumb || isUserThumb)"></fa>
        </div>
    `,
    styles: [`
        .UserThumbnail > img { padding: 10px; }
    `]
})

export class UserThumbComponent implements OnInit {
    @ViewChild('img') img: ElementRef;
    @Input() thumb: any;
    @Input() isUserThumb: boolean;
    isChef: boolean = isChef();
    isProman: boolean = isProman();
    isSmarton: boolean = isSmarton();
    isLocal: boolean = isLocalhost();
    placeholderUrl: string = DEFAULT_USER_PLACEHOLDER;

    constructor(

    )  {

    }

    ngOnInit() {
        if (this.isUserThumb) {
            this.placeholderUrl = this.getPlaceHolder();
        }
    }

    handleError(error: any) {
        error.target.src = this.placeholderUrl;
    }

    getPlaceHolder = () => {
        if (this.isProman) {
            return PROMAN_LOGO_PLACEHOLDER;
        }
        if (this.isSmarton) {
            return SMARTON_LOGO_PLACEHOLDER;
        }
        if (this.isLocal) {
            return LOCALHOST_LOGO_PLACEHOLDER;
        }

    }

}
