import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import { PromanParametersModule } from '@proman/parameters/proman-parameters.module';
import { MonthpickerComponent } from '@frontend/inputs/components/monthpicker.component';
import { ParameterListComponent } from '@frontend/shared/components/parameter-list.component';
import { SwitchComponent } from '@frontend/inputs/components/switch.component';
import { TimeIntervalComponent } from '@frontend/inputs/components/time-interval.component';
import { FilesManagerComponent } from '@frontend/shared/components/files-manager.component';
import { PriceComponent } from '@frontend/inputs/components/price.component';
import { ParameterWorkgroupComponent } from '@frontend/shared/components/parameter-workgroup.component';
import { TagsComponent } from '@frontend/shared/components/tags.component';
import { IconSelectComponent } from '@frontend/shared/components/icon-select.component';
import { AccountCategoryTypeBtnComponent } from '@frontend/accounting/components/account-category-type-btn.component';
import { EmojiComponent } from '@frontend/inputs/components/emoji.component';
import { HtmlTxtComponent } from '@frontend/inputs/components/html-txt.component';
import { AccountingPanelComponent } from '@frontend/shared/components/accounting-panel.component';
import { PromanSelectComponent } from '@proman/select';
import { InputsModule } from '@frontend/inputs/inputs.module';
import { ParametersComponent } from '@frontend/shared/components/parameters.component';
import { PipesModule } from '@proman/shared/pipes/pipes.module';
import { DragulaModule } from 'ng2-dragula';
import { PromanCommonComponentsModule } from '@proman/common-components/proman-common-components.module';
import { PromanExpressionModule } from '@proman/expression/proman-expression.module';
import { SharedDirectivesModule } from '@proman/shared/directives/shared-directives.module';
import {
  ParameterDropdownDefinitionComponent
} from '@frontend/parameters/components/parameter-dropdown-definition.component';
import { CorporatePanelComponent } from '@frontend/shared/components/corporate-panel.component';

const COMPONENTS = [
  ParametersComponent,
  ParameterWorkgroupComponent,
  ParameterListComponent,
]

@NgModule({
  declarations: COMPONENTS,
  imports: [
    CommonModule,
    FlexLayoutModule,
    InputsModule,
    DragulaModule.forRoot(),
    PromanSelectComponent,
    PromanCommonComponentsModule,
    PromanExpressionModule,
    SharedDirectivesModule,
    PipesModule,
    PromanParametersModule.forRoot({
      templates: {
        'month': { component: MonthpickerComponent },
        'list': { component: ParameterListComponent },
        'boolean': { component: SwitchComponent },
        'duration': { component: TimeIntervalComponent },
        'files': { component: FilesManagerComponent },
        'price': { component: PriceComponent },
        'dropdown': { component: ParameterDropdownDefinitionComponent },
        'workgroup': {
          config: {
            groupBy: 'workgroupName',
          },
          component: ParameterWorkgroupComponent,
        },
        'tags': { component: TagsComponent },
        'icon': { component: IconSelectComponent },
        'namespaces': { component: CorporatePanelComponent },
        'account_category_type': { component: AccountCategoryTypeBtnComponent },
        'emoji': {
          config: {
            noPadding: true,
          },
          component: EmojiComponent,
        },
        'html': {
          component: HtmlTxtComponent,
        },
        'accounting': {
          component: AccountingPanelComponent,
          config: {
            groupInput: true,
          }
        }
      }
    })
  ],
  providers: [],
  exports: [
    PromanParametersModule,
    ...COMPONENTS
  ]
})

export class  FrontendParametersModule {}
