import {
    Component,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    ElementRef,
    AfterViewInit,
    OnDestroy, OnInit, OnChanges, SimpleChanges,
} from '@angular/core';
import { Entity } from '@proman/services/entity.service';
import { AuthService } from '@proman/services/auth.service';
import { UploaderService } from '@proman/services/uploader.service';
import { UiPreferencesService, UI_CLIPBOARD_PASTE_TARGET } from '@proman/services/ui-preferences.service';
import { ArticleTest, Order, Production, PromanFile, SystemOptions } from '@proman/interfaces/entity-interfaces';
import { HttpProgressEvent } from '@angular/common/http';
import { copyToClipboard, getScreenWidth, isArray } from '@proman/utils';
import { Dialog } from '@frontend/shared/services/dialog.service';
import { ImagePathService } from '@proman/services/image-path.service';
import { ToastService } from '@proman/services/toast.service';
import { FilePreviewService } from '@proman/services/file-preview.service';
import { ACL } from '@proman/services/acl.service';
import { getSystemOptions } from '@proman/store/system-options';
import { Store } from '@ngrx/store';
import { InputDialogComponent } from '@frontend/shared/components/input-dialog.component';
import { dateTime } from '@proman/interfaces/common.interface';
import { CropperDialogComponent } from '@proman/cropper/cropper-dialog.component';
import { EntityEditDialogComponent } from '@frontend/shared/components/entity-edit-dialog.component';
import { QueryExpressionService } from '@proman/services/query-expression.service';

@Component({
    selector: 'pm-files-manager',
    template: `
        <div class="FilesManager" #element fxLayout="column">
            <div class="FilesUploadOverlay">
                <span *ngIf="!config.preventOverlay && overlayMoved">
                    {{ 'drop_files_here_to_upload' | translate }}
                </span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center">
                <pro-label>{{ config?.label || 'files' | translate }}</pro-label>
                <pro-btn *ngIf="!disabled && onChange.observers.length"
                        (onClick)="showDialog()"
                        icon="upload"
                        theme="primary"
                        [tooltip]="'upload' | translate"
                ></pro-btn>
                <pro-btn *ngIf="!disabled && onChange.observers.length && config.canAddFileRef"
                        (onClick)="addFileById()"
                        icon="file-plus"
                        theme="accent"
                        [tooltip]="'upload' | translate"
                ></pro-btn>
                <div fxFlex=""></div>
                <i class="FilesManager-footer"
                   *ngIf="!disabled">
                    {{ 'drop_files_here_to_upload' | translate }}
                </i>
            </div>
            <div *ngIf="!$any(value)?.length" fxLayout="column">
                <hr>
                <pro-no-records></pro-no-records>
            </div>
            <mat-list *ngIf="$any(value)?.length || $any(value)?.length === 0">
                <div *ngFor="let file of value; let $index = index;">
                    <hr>
                    <div  *ngIf="file" fxLayout="row"  (contextmenu)="editFileName(file, $event)">
                        <pro-thumbnail [file]="file"
                                      [isAvatar]="true"></pro-thumbnail>
                        <div fxFlex fxLayout="column">
                            <div fxLayout="row">
                                <h3 class="FilesManager-fileName" fxFlex>
                                    <span>{{ file.name }}</span>
                                </h3>
                                <pm-tags *ngIf="file.tags?.length" [item]="file"></pm-tags>
                            </div>
                            <p class="FilesManager-uploadDate">
                                {{ file.createdAt | proDateTime }}
                                <ng-container *ngIf="file.createdBy">
                                   {{ 'uploaded_by' | translate }} {{ file.createdBy.name || file.createdBy }}
                                </ng-container>
                            </p>
                            <p class="FilesManager-restrictions" *ngIf="file.accessibleTimes || file.accessibleUntil">
                                <ng-container *ngIf="file.accessibleTimes">{{ 'available_for' | translate}} {{ file.accessibleTimes }} {{ 'times' | translate }}</ng-container>
                                <ng-container *ngIf="file.accessibleTimes && file.accessibleUntil">. &nbsp;</ng-container>
                                <ng-container *ngIf="file.accessibleUntil">{{ 'available_until' | translate}} {{ file.accessibleUntil | proDateTime }}</ng-container>
                            </p>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <pro-checkbox *ngIf="production"
                                        [value]="file.selected"
                                        (onChange)="toggleProductionFiles(file, $event)"></pro-checkbox>
                            <!--<pro-btn *ngIf="canEditFiles"
                                    (onClick)="editFile(file)"
                                    icon="edit"
                                    theme="accent"
                                    [tooltip]="'edit_file_details' | translate"
                            ></pro-btn>-->
                            <pro-btn *ngIf="config?.identificationDocument"
                                     (click)="callOcr(file)"
                                     icon="face-viewfinder"
                                     [tooltip]="'scan_with_ocr' | translate"
                                     theme="accent"></pro-btn>
                            <pro-btn *ngIf="config?.copyLink"
                                    (click)="copyFileUrl(file)"
                                    icon="clipboard"
                                    theme="accent"
                                    [tooltip]="'copy_file_url' | translate"
                            ></pro-btn>
                            <pro-btn *ngIf="order && (order.previewFile?.id !== file.id)"
                                    (click)="setPreviewFile(file)"
                                    icon="check-square"
                                    theme="accent"
                                    [tooltip]="'set_as_order_picture' | translate"
                            ></pro-btn>
                            <pro-btn *ngIf="systemOptions?.useFileRestriction && config?.useFileRestrictions && canEditFiles"
                                     theme="accent"
                                     icon="file-circle-minus"
                                     [tooltip]="'edit_file_restrictions' | translate"
                                     (click)="editFileRestrictions(file)"></pro-btn>
                            <pro-btn (click)="onDownload(file)"
                                    icon="download"
                                    theme="accent"
                                    [tooltip]="'download' | translate"
                            ></pro-btn>
                          <pro-btn *ngIf="articleTest"
                                   (click)="onDownloadPdf(file)"
                                   icon="file-pdf"
                                   theme="accent"
                                   [tooltip]="'download_as_pdf' | translate"
                          ></pro-btn>
                            <pro-btn *ngIf="onRemove.observers.length && !disabled || config?.modelWithin && !config?.noRemove || this.inArticleOperationsCanDeleteFiles"
                                    (click)="handleRemove(file, $index)"
                                    icon="trash"
                                    theme="warn"
                                    [tooltip]="'delete' | translate"
                            ></pro-btn>
                        </div>
                    </div>
                </div>
            </mat-list>
            <mat-progress-bar *ngIf="uploadInstance.progress"
                    class="mat-accent"
                    [color]="'accent'"
                    [mode]="'determinate'"
                    [value]="uploadInstance.progress">
            </mat-progress-bar>
        </div>
    `,
    styles: [`
        .FilesUploadOverlay {
            position: absolute;
            display: none;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(33, 150, 243, 0.2);
            border: 2px solid #448aff;
            z-index: 9;
        }

        .FilesUploadOverlay span {
            font-size: 7em;
            position:absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: white;
            text-align: center;
            text-anchor: middle;
        }

        .FilesManager-fileName {
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0.010em;
            line-height: 1.2em;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin: 0.5em;
        }

        .FilesManager-uploadDate, .FilesManager-restrictions {
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0.010em;
            line-height: 1.6em;
            margin: 0 0.5em;
            color: rgba(0,0,0,0.4);
        }
    `]
})

export class FilesManagerComponent implements AfterViewInit, OnDestroy, OnInit, OnChanges {
    @Input() value: PromanFile|PromanFile[];
    @Input() disabled: boolean = false;
    @Input() config: {
        label?: string;
        preventOverlay?: boolean;
        modelWithin?: boolean;
        data?: any;
        multiple?: boolean;
        noRemove?: boolean;
        copyLink?: boolean;
        useFileRestrictions?: boolean;
        resizeImageRatio?: number;
        resizeImage?: boolean;
        canAddFileRef?: boolean;
        identificationDocument?: boolean;
    } = {};
    @Input() order: Order;
    @Input() articleTest: ArticleTest;
    @Input() production: Production;
    @Output() onRemove: EventEmitter<any> = new EventEmitter();
    @Output() onChange: EventEmitter<PromanFile[]> = new EventEmitter();
    @ViewChild('element', { static: true }) element: ElementRef;

    uploaderConfig: any;
    uploadInstance: any = {};
    overlayElement: HTMLElement;
    overlayMoved: boolean;
    isPasteEnabled: boolean;
    canEditFiles: boolean;
    inArticleOperationsCanDeleteFiles: boolean;
    systemOptions: SystemOptions;

    constructor(
        private Uploader: UploaderService,
        private Auth: AuthService,
        private UiPrefs: UiPreferencesService,
        private Entity: Entity,
        private ImagePath: ImagePathService,
        private Dialog: Dialog,
        private Toast: ToastService,
        private FilePreview: FilePreviewService,
        private store: Store,
        private QueryExpression: QueryExpressionService,
        public ACL: ACL,
    ) {
        this.isPasteEnabled = !this.UiPrefs.get(UI_CLIPBOARD_PASTE_TARGET);
        this.store.select(getSystemOptions).subscribe((value) => this.systemOptions = value);
        this.canEditFiles = this.ACL.check('file.edit');
    }

    ngOnInit() {
        if (!this.value) return;
        let inArticleOperations: boolean = false;
        if (this.config?.multiple === false && this.value) {
            if (!isArray(this.value)) {
                this.value = [this.value];
            }
        }
        this.value = (this.value as PromanFile[]).sort((a, b) => a.createdAt > b.createdAt ? 1 : (a.createdAt == b.createdAt ? 0 : -1));

        if (window.location.href.includes('/events') && window.location.href.includes('/operations')) {
            inArticleOperations = true;
        }
        // if (inArticleOperations) {
        //     let articleId = window.location.href.split('/').at(2);
        //     let operationId = window.location.href.split('/').at(4);
        //     this.inArticleOperationsCanDeleteFiles = this.order.operations.find(operation => operation.articleOperation.article.id === parseInt(articleId)).articleOperation.operation.deleteOrderFiles
        // }

        if (!this.onChange.observers.length) return;
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (this.config?.multiple === false && this.value) {
            if (!isArray(this.value)) {
                this.value = [this.value];
            }
        }

    }

    ngAfterViewInit() {

        let element = this.element.nativeElement;

        if (!document.querySelectorAll('.Upload') && document.querySelectorAll('.FilesManager').length === 1) {
            element = document.querySelector('body');
            document.body.appendChild(element.querySelector('.FilesUploadOverlay'));
            this.overlayMoved = true;

        }
        new window.Dragster(element);

        element.addEventListener('drop', this.handleDrop);
        element.addEventListener('dragenter', this.handleDragEnter);
        element.addEventListener('dragover', this.handleDragOver);
        element.addEventListener('dragster:enter', this.showOverlay);
        element.addEventListener('dragster:leave', this.hideOverlay);

        this.overlayElement = element.querySelector('.FilesUploadOverlay');
        this.uploaderConfig = { multiple: this.config.multiple };

        if (this.isPasteEnabled) document.addEventListener('paste', this.handlePaste);
    }

    ngOnDestroy() {
        const element = this.element.nativeElement;

        element.removeEventListener('drop', this.handleDrop);
        element.removeEventListener('dragenter', this.handleDragEnter);
        element.removeEventListener('dragover', this.handleDragOver);
        element.removeEventListener('dragster:enter', this.showOverlay);
        element.removeEventListener('dragster:leave', this.hideOverlay);

        if (this.isPasteEnabled) document.removeEventListener('paste', this.handlePaste);

        if (this.overlayMoved) {
            const overlayElement = document.querySelector('.FilesUploadOverlay');

            overlayElement?.parentNode?.removeChild(overlayElement);

        }
    }

    getProgress = (event: HttpProgressEvent) => event.loaded / event.total * 100;

    uploadProgressCallback = (event: HttpProgressEvent) => {
        this.uploadInstance.progress = this.getProgress(event);
    };

    handleDragEnter = (event: any) => { event.preventDefault(); this.showOverlay() };

    handleDragOver = (event: any) => {
        event.stopPropagation();
        event.preventDefault();

        return false;
    };

    handleDrop = (event: DragEvent) => {
        this.hideOverlay();
        event.stopPropagation();
        event.preventDefault();

        if (this.disabled) return;

        const files = event.dataTransfer.files;
        this.tryResizingUpload(files);

    };

    tryResizingUpload = (files: FileList) => {
        if (this.canResizeFile(files)) {
            this.Dialog.open(CropperDialogComponent,
                { file: files[0], aspectRatio: this.config.resizeImageRatio },
                { width: `${getScreenWidth(80)}px`, disableClose: true })
                .then((result: File) => {
                    this.uploadFiles([result]);
                });
        } else {
            this.uploadFiles(files)
        }
    };

    canResizeFile = (files: File[]|FileList) => {
        return this.config.resizeImage && files.length === 1 && files[0].type?.includes('image');
    };

    uploadFiles = (files: FileList|File[]) => {
        this.Uploader
            .init(this.uploadProgressCallback, this.config.data)
            .upload(files as unknown as File[], this.uploaderConfig)
            .then(this.handleUploadSuccess)
            .then(() => { this.uploadInstance.progress = null; })
            .catch(() => { this.uploadInstance.progress = null; });
    };

    showOverlay = () => { if (!this.disabled) this.overlayElement.style.setProperty('display', 'block'); };
    hideOverlay = () => this.overlayElement.style.setProperty('display', 'none');

    onDownload = (file: PromanFile) => {
        this.FilePreview.download(file);
    };
    onDownloadPdf = (file: PromanFile) => {
        this.FilePreview.downloadPdf(file);
    };

    showDialog = () => {
        this.Uploader
            .init(this.uploadProgressCallback, this.config.data)
            .show(this.uploaderConfig)
            .then(this.handleUploadSuccess)
            .then(() => { this.uploadInstance.progress = null; })
            .catch(() => { this.uploadInstance.progress = null; });
    };

    handleUploadSuccess = (files: PromanFile[]) => {

        // if (this.config.modelWithin) {           // Unused?
            if (!this.value) this.value = [];
            for (const item of files) {
                (this.value as PromanFile[]).push(item);
            }
        // }
        // this.onChange.emit(this.config.modelWithin ? (this.value as PromanFile[]) : files);
        if (!this.config.multiple && (this.value as any).length === 1) {
            this.onChange.emit((this.value[0] as any));
        } else {
            this.onChange.emit((this.value as PromanFile[]))
        }
    };

    setPreviewFile = (file: any) => {
        this.Entity.get('order')
            .addAssociation({ id: this.order.id, previewFile: file.id })
            .then(() => this.order.previewFile = file);
    };

    toggleProductionFiles = (file: any, selected: boolean) => {
        let hiddenOrderFiles = this.production.hiddenOrderFiles || [];

        selected ? hiddenOrderFiles.splice(hiddenOrderFiles.indexOf(file.id), 1) : hiddenOrderFiles.push(file.id);

        return this.Entity
             .get('production')
            .update({ id: this.production.id, hiddenOrderFiles: hiddenOrderFiles.length ? hiddenOrderFiles : [null] });

    };

    handlePaste = (event: any) => {
        const files = event.clipboardData.files;
        this.tryResizingUpload(files);
    };

    handleRemove = (file: PromanFile, index: number) => {
        const removeCallback = () => {
            if (this.config.modelWithin) {
                this.onChange.emit((this.value as PromanFile[]).map((item) => {

                    if (file.id === item.id) {
                        item.id = item.id * -1; // removable id is negative

                    }

                    return item;
                }) );
                (this.value as PromanFile[]).splice(index, 1);

            } else {
                this.onRemove.emit({ file, index });

            }
        };

        this.Dialog.confirm(null, 'confirm', removeCallback);

    };

    copyFileUrl = (file: PromanFile) => {
        copyToClipboard(this.ImagePath.getFile(file, 'pdf'));
        this.Toast.pop('info', 'copied_to_clipboard', { value: 'URL' });
    };

    editFileName = (file: PromanFile, event: MouseEvent) => {
        event.preventDefault();
        this.Dialog.open(EntityEditDialogComponent, {
            entity: 'file',
            item: file,
            mainField: { key: 'name', name: 'name', type: 'text', config: {} },
            parameters: [
                {
                    key: 'id',
                    name: 'id',
                    type: 'string',
                    config: { disabled: true }
                },
                {
                    key: 'extension',
                    name: 'type',
                    type: 'string',
                    config: { disabled: true }
                },
                {
                    key: 'createdAt',
                    name: 'created_at',
                    type: 'date',
                    config: { disabled: true }
                },
            ]

        }).then(() =>   {});
    };

    editFileRestrictions = (file: PromanFile) => {
        this.Dialog.open(InputDialogComponent, {
            header: 'restrictions',
            item: file,
            mainField: {
                key: 'times',
                name: 'times',
                value: file.accessibleTimes,
                config: { required: false },
            },
            parameters: [
                {
                    key: 'date',
                    name: 'date',
                    type: 'date',
                    config: {},
                    value: file.accessibleUntil
                }
            ]
        }).then((response: { times: number, date: dateTime }) => {
            this.Entity.get('file').update({ id: file.id, accessibleTimes: response.times, accessibleUntil: response.date });
        });
    };

    addFileById = () => {
        this.Dialog.open(InputDialogComponent, {
            header: 'enter_file_id',
            mainField: {
                key: 'id',
                name: 'id',
                type: 'number',
                config: { required: true },
            }
        }).then((response: { id: number }) => {
            this.Entity.get('file').get({ id: this.QueryExpression.eqStrict(response.id) })
                .then((file: PromanFile) => {
                    if (!!file) {
                        (this.value as PromanFile[]).push(file);
                        this.onChange.emit([file]);
                    }
                })
                .catch(() => {
                    this.Toast.pop('error', 'error_adding_existing_file');
                });
        });
    };

    callOcr = (file: PromanFile) => {
        this.Entity.get('ocr').recognizeIndentificationDocument({ newId: file.newId });
    }
}
