import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef, OnInit, OnChanges } from '@angular/core';

@Component({
    selector: 'pm-errors',
    template: `
        <pm-warning *ngFor="let e of errorValues" class="fade">{{ e }}</pm-warning>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class ErrorsComponent implements OnInit, OnChanges {
    @Input() errors: any;
    errorValues: any;

    constructor(
        private cd: ChangeDetectorRef
    ) {

    }

    ngOnInit() {

    }

    ngOnChanges() {
        this.handleErrors();

        this.cd.markForCheck();
    }

    handleErrors() {
        this.errorValues = [];

        for (let key in this.errors) {
            let error = this.errors[key];

            this.errorValues.push(`error.${error.code} ${error.value && error.value.join(', ') || error.message}`);
        }

    }

}
