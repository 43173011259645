import { Component, ElementRef, ViewChild, Input } from '@angular/core';

/**
 * `pmWarning` component
 *
 */
@Component({
    selector: 'pm-warning',
    template: `
        <div #element
             fxLayout="row"
             fxLayoutAlign="start center"
             class="Warning">
            <fa name="exclamation-triangle"></fa>
            <div class="Warning-content">
                <ng-content></ng-content>
            </div>
            <div fxFlex></div>
            <pro-btn *ngIf="!preventClose"
                    icon="times"
                    theme="white"
                    tooltip="close"
                    (onClick)="hide($event)"
            ></pro-btn>
        </div>
    `
})

export class WarningComponent {
    label: string;
    @Input() preventClose: boolean;
    @ViewChild('element', { static: true }) element: ElementRef;

    hide($event: MouseEvent) {
        $event.stopPropagation();
        this.element.nativeElement.remove();
    }
}
