import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { delay } from '@proman/utils';
import $ from 'jquery';
import { Html5QrcodeScanner } from 'html5-qrcode';
import { Html5QrcodeResult } from 'html5-qrcode/esm/core';

@Component({
    selector: 'pm-camera-qr-reader',
    template: `
        <div #element>
            <div *ngIf="!status" (click)="startCamera()" class="Padding-16">
                <pro-label>
                    <fa name="barcode-read"></fa>
                    {{ 'click_to_use_camera' | translate }}
                </pro-label>
            </div>
            <div *ngIf="status" class="Padding" style="overflow: hidden;">
                <div id="loadingMessage">🎥 Unable to access video stream (please make sure you have a webcam enabled)</div>
                <video id="video" width="320" height="240" hidden></video>
                <canvas id='canvas' width="320" height="240" [ngClass]="{ _snapshot: _paused }"></canvas>
            </div>
         <ng-container>
             <script src="https://unpkg.com/html5-qrcode/minified/html5-qrcode.min.js"></script>

             <div id="reader"></div>
             <div id="qr-reader" style="width:500px"></div>
             <div id="qr-reader-results"></div>
         </ng-container>
        </div>
    `,
    styles: [`
        ._snapshot {
            outline: 2px solid red;
        }
        #html5qrreader {
            width: 600px;
        }
    `]
})

export class CameraQrReaderComponent implements OnInit, AfterViewInit, OnDestroy {
    callback: any;
    url: string;
    stream: MediaStream;
    quagga: any;
    _paused: boolean = false;
    status: boolean = false;
    @Input() multiple: boolean;
    @ViewChild('element', { static: true }) element: ElementRef;
    @Output() onCodeRead: EventEmitter<string> = new EventEmitter<string>();

    html5QrcodeScanner: Html5QrcodeScanner;

    constructor(

    ) {

    }

    ngOnInit() {

        if (!window.jsQR) {
            $.getScript('./assets/js/jsQR.js', () => {});
        }

        if (!window.Quagga) {
            $.getScript('./assets/js/quaggaJS.js', () => {

            });
        }
        // navigator.mediaDevices.enumerateDevices().then((devices) => {
        //     console.log('media devices', devices);
        // });
        //
        // console.log('supported media constraits',         navigator.mediaDevices.getSupportedConstraints()
        // )

    }

    ngAfterViewInit() {
        console.log('document.querySelector(\'#reader\').getBoundingClientRect()', document.querySelector('#reader').getBoundingClientRect());
        const width = document.querySelector('#reader').getBoundingClientRect().width;
        const qrbox = Math.floor(width / 10) * 10;
        this.html5QrcodeScanner = new Html5QrcodeScanner(
            'reader',
            {
                fps: 4,
                qrbox,
                experimentalFeatures: {
                    useBarCodeDetectorIfSupported: true
                }
            }, true);

        const onScanSuccess = (decodedText: string, decodedResult: Html5QrcodeResult): void => {
            // handle the scanned code as you like, for example:
            console.log(`Code matched = ${decodedText}`, decodedResult);
            this.onCodeRead.emit(decodedText);
            if (!this.multiple) {
                this.html5QrcodeScanner.pause();
                this.html5QrcodeScanner.clear();
            }
        };

        const onScanFailure = (error: unknown) => {
            // handle scan failure, usually better to ignore and keep scanning.
            // for example:
            // console.warn(`Code scan error = ${error}`);
        };

        this.html5QrcodeScanner.render(onScanSuccess, onScanFailure);

    }

    ngOnDestroy() {
        try {
            if (this.stream) this.stream.getTracks().forEach((track: any) => track.stop());
            window.Quagga.stop();

        } catch (e) {

        }

        window.Quagga = null;

        try {
            this.html5QrcodeScanner.pause();
            this.html5QrcodeScanner.clear();
        } catch (e) {

        }

    }

    async startCamera() {
        this.status = true;

        while (!(document.getElementById('video') && document.getElementById('canvas'))) {
            await delay(500);
        }

        const video: HTMLVideoElement = document.createElement('video');
        const canvasElement: HTMLCanvasElement = document.getElementById('canvas') as HTMLCanvasElement;
        const canvas = canvasElement.getContext('2d');
        const loadingMessage = document.getElementById('loadingMessage');

        canvasElement.width = Math.min(320, this.element.nativeElement.getBoundingClientRect().width);
        canvasElement.height = canvasElement.width * 3 / 4;

        const tick = () => {
            loadingMessage.innerText = '⌛ Loading video...';
            loadingMessage.hidden = true;

            if (video.readyState === video.HAVE_ENOUGH_DATA && !this._paused) {
                canvas.drawImage(video, 0, 0, canvasElement.width, canvasElement.height);
                const imageData = canvas.getImageData(0, 0, canvasElement.width, canvasElement.height);
                const code = window.jsQR(imageData.data, imageData.width, imageData.height, { inversionAttempts: 'dontInvert' });

                if (code) {
                    this.url = code.data;
                    this.onCodeRead.emit(this.url);

                    if (!this.multiple) {
                        this.status = false;
                        this.stopStream();
                        return;
                    } else {
                        this._paused = true;

                        setTimeout(() => this._paused = false, 500);
                    }

                }
            }

            requestAnimationFrame(tick);

        };

        window.Quagga.init({
            inputStream : {
                name : 'Live',
                type : 'LiveStream',
                target: document.querySelector('#canvas')    // Or '#yourElement' (optional)
            },
            decoder : {
                readers : ['code_128_reader']
            }
        }, (err: any) => {
            if (err) {
                console.warn('QuaggaJS error', err);
                return
            }

            window.Quagga.start();
            window.Quagga.onDetected((data: any) => {

                this.url = data.codeResult.code;
                this.onCodeRead.emit(this.url);

                if (!this.multiple) {
                    this.status = false;
                    this.stopStream();
                    window.Quagga.stop();
                    return;
                } else {
                    this._paused = true;

                    setTimeout(() => this._paused = false, 500);
                }
            });
        });

        navigator.mediaDevices.getUserMedia({ video: true }).then((stream) => {
            this.stream = stream;
            video.srcObject = stream;
            video.setAttribute('playsinline', 'true');
            video.play();
            requestAnimationFrame(tick);
        });

    }

    stopStream = () => {

        try {
            this.stream.getTracks().forEach((track) => track.stop());
            // this.stream = null;

        } catch (e) {
            // this.stream = null;

        }

    };

}
